import axios from 'axios';
import NProgress from 'nprogress';
import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';

import router from '@/router';
import store from '@/store';

let user;

try {
  user = JSON.parse(localStorage.getItem('user') || '');
} catch (e) {
  user = null;
}

const Http = axios.create({
  baseURL: `${process.env.VUE_APP_SPUR_API}`,
  headers: {
    'Content-Type': 'application/json',
    'accept-language': navigator.language,
  },
});

if (user) {
  Http.defaults.headers['Authorization'] = `Bearer ${user.token}`;
}

// before a request is made start the nprogress
Http.interceptors.request.use(
  (config) => {
    store.dispatch('errors/clear');
    NProgress.start();
    return config;
  },
  (error) => {
    NProgress.done();
    return Promise.reject(error);
  },
);

// once a response is returned stop nprogress
Http.interceptors.response.use(
  (response) => {
    NProgress.done();
    return response;
  },
  (error) => {
    NProgress.done();
    return Promise.reject(error);
  },
);

// handle error codes
Http.interceptors.response.use(
  (response) => response,
  (error) => {
    const status = error.response ? error.response.status : error.status;

    // logout and redirect if unauthenticated
    if (status === 401) {
      store.dispatch('auth/logout');
      router.push({ name: 'login' });
      store.dispatch(
        'notifications/notify',
        { type: 'danger', text: useI18n().t('BASE.TOASTS.UNAUTHORIZED') },
        { root: true },
      );
    }

    // log error
    if (status === 403) {
      const route = useRoute();
      store.dispatch('rollbar/report', {
        message: {
          error: error,
          route: route.path,
          routeName: route.name,
          routeParams: route.params,
          routeQuery: route.query,
        },
        level: 'debug',
      });
    }

    // set global errors state if unprocessable
    if (status === 422) {
      const errors = error.response
        ? error.response.data.errors
        : error.data.errors;
      store.dispatch('errors/set', errors);
    }

    // log error and notify user
    if (status >= 500) {
      const route = useRoute();
      store.dispatch('rollbar/report', {
        message: {
          error: error,
          route: route.path,
          routeName: route.name,
          routeParams: route.params,
          routeQuery: route.query,
        },
        level: 'debug',
      });

      store.dispatch(
        'notifications/notify',
        {
          type: 'danger',
          text: useI18n().t('BASE.TOASTS.INTERNAL_SERVER_ERROR'),
        },
        { root: true },
      );
    }

    return Promise.reject(error);
  },
);

export { Http };
