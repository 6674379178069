import { Http } from '@/lib/common/http';
import {
  FacebookAuthProvider,
  getAuth,
  GoogleAuthProvider,
  signInWithPopup,
} from 'firebase/auth';

export enum SocialAuthProviders {
  // eslint-disable-next-line
  GOOGLE = 'google',
  // eslint-disable-next-line
  FACEBOOK = 'facebook',
}

export const SocialAuthService = {
  async loginWithProvider(provider: SocialAuthProviders) {
    const providers = {
      [SocialAuthProviders.GOOGLE]: GoogleAuthProvider,
      [SocialAuthProviders.FACEBOOK]: FacebookAuthProvider,
    };
    const authProvider = new providers[provider]();

    return await signInWithPopup(getAuth(), authProvider).then(
      (socialAuthResponse) => {
        return {
          provider,
          email: socialAuthResponse.user && socialAuthResponse.user.email,
          account_id:
            socialAuthResponse.user &&
            socialAuthResponse.user.providerData &&
            socialAuthResponse.user.providerData.length &&
            socialAuthResponse.user.providerData[0] &&
            socialAuthResponse.user.providerData[0].uid,
        };
      },
    );
  },

  loginSpur(provider: SocialAuthProviders, email: string, account_id: string) {
    return Http.post('/social-auth', {
      provider_name: provider,
      email,
      account_id,
    });
  },
};
