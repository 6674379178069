import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getPerformance } from 'firebase/performance';

const firebaseApp = initializeApp({
  apiKey: process.env.VUE_APP_FB_API_KEY,
  authDomain: process.env.VUE_APP_FB_API_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_FB_PROJECT_ID,
  appId: process.env.VUE_APP_FB_APP_ID,
  measurementId: process.env.VUE_APP_FB_MEASUREMENT_ID,
});

if (process.env.VUE_APP_MODE === 'production') {
  getAnalytics(firebaseApp);
  getPerformance(firebaseApp);
}

export { firebaseApp };
