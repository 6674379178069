import { AxiosResponse } from 'axios';

import { Http } from '@/lib/common/http';
import { ProfileResponse, ProfileRequest } from './profile.types';

export const ProfileService = {
  get(): Promise<AxiosResponse<ProfileResponse>> {
    return Http.get('/profile');
  },

  update(
    data: Partial<ProfileRequest>,
  ): Promise<AxiosResponse<ProfileResponse>> {
    return Http.put('/profile', data);
  },
};
