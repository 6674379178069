import { AxiosResponse } from 'axios';

import { Http } from '@/lib/common/http';
import {
  BenefitEnrollmentsRequest,
  BenefitEnrollmentsResponse,
  BenefitsBeneficiaryRequest,
  BenefitsBeneficiaryResponse,
  BenefitsDependentRequest,
  BenefitsDependentResponse,
  BenefitsPlansCoveragesResponse,
  BenefitsProviderPlansResponse,
  BenefitsProvidersResponse,
  QualifyingEventRequest,
  QualifyingEventsResponse,
} from './benefits.types';
import { StatusResponse } from '@/lib/services/base.types';

export const BenefitsService = {
  getBenefitDependents(): Promise<
    AxiosResponse<Array<BenefitsDependentResponse>>
  > {
    return Http.get<Array<BenefitsDependentResponse>>(`/benefit-dependents`);
  },

  getBenefitBeneficiaries(): Promise<
    AxiosResponse<Array<BenefitsBeneficiaryResponse>>
  > {
    return Http.get<Array<BenefitsBeneficiaryResponse>>(
      `/benefit-beneficiaries`,
    );
  },

  getBenefitProviders(): Promise<
    AxiosResponse<Array<BenefitsProvidersResponse>>
  > {
    return Http.get<Array<BenefitsProvidersResponse>>(`/benefit-providers`);
  },

  getPlans(
    category: string,
  ): Promise<AxiosResponse<Array<BenefitsProviderPlansResponse>>> {
    return Http.get<Array<BenefitsProviderPlansResponse>>(`benefit-plans`, {
      params: { category },
    });
  },

  getAllPlans(
    event_type = null,
  ): Promise<AxiosResponse<Array<BenefitsProviderPlansResponse>>> {
    return Http.get<Array<BenefitsProviderPlansResponse>>(`benefit-plans`, {
      params: { event_type },
    });
  },

  getBenefitProviderPlans(
    provider_id: number,
  ): Promise<AxiosResponse<Array<BenefitsProviderPlansResponse>>> {
    return Http.get<Array<BenefitsProviderPlansResponse>>(
      `benefit-providers/${provider_id}/plans`,
    );
  },

  getBenefitPlanCoverages(
    plan_id: number,
  ): Promise<AxiosResponse<Array<BenefitsPlansCoveragesResponse>>> {
    return Http.get<Array<BenefitsPlansCoveragesResponse>>(
      `benefit-plans/${plan_id}/coverages`,
    );
  },

  getBenefitEnrollments(): Promise<
    AxiosResponse<Array<BenefitEnrollmentsResponse>>
  > {
    return Http.get<Array<BenefitEnrollmentsResponse>>(`benefit-enrollments`);
  },

  getBenefitEnrollmentsById(
    id: number,
  ): Promise<AxiosResponse<BenefitEnrollmentsResponse>> {
    return Http.get<BenefitEnrollmentsResponse>(`benefit-enrollments/${id}`);
  },

  removeBenefitBeneficiary(
    dependent_id: number,
  ): Promise<AxiosResponse<StatusResponse>> {
    return Http.delete<StatusResponse>(
      `/benefit-beneficiaries/${dependent_id}`,
    );
  },

  saveBenefitBeneficiary(
    payload: BenefitsBeneficiaryRequest,
  ): Promise<AxiosResponse<BenefitsBeneficiaryResponse>> {
    return Http.post<BenefitsBeneficiaryResponse>(
      `/benefit-beneficiaries`,
      payload,
    );
  },

  removeBenefitDependent(
    dependent_id: number,
  ): Promise<AxiosResponse<StatusResponse>> {
    return Http.delete<StatusResponse>(`/benefit-dependents/${dependent_id}`);
  },

  saveBenefitDependent(
    payload: BenefitsDependentRequest,
  ): Promise<AxiosResponse<BenefitsDependentResponse>> {
    return Http.post<BenefitsDependentResponse>(`/benefit-dependents`, payload);
  },

  saveBenefitEnrollment(
    payload: BenefitEnrollmentsRequest,
  ): Promise<AxiosResponse<Array<StatusResponse>>> {
    return Http.post<Array<StatusResponse>>(`benefit-enrollments`, payload);
  },

  deleteBenefitEnrollment(enrollment_id: number): Promise<AxiosResponse<any>> {
    return Http.delete<Array<StatusResponse>>(
      `benefit-enrollments/${enrollment_id}`,
    );
  },

  getCoverageCalculation(plan_id: string, enroll: Array<number>, config = {}) {
    return Http.get(`/coverage-calculator`, {
      ...{ params: { plan_id, enroll } },
      ...config,
    });
  },

  getCoverageTerms(plan_id: string, enroll: Array<number>, event_type = null) {
    return Http.get(`/coverage-terms`, {
      ...{ params: { plan_id, enroll, event_type } },
    });
  },

  removeDependentFromEnrollment(enrollment_id, dependent_id) {
    return Http.delete(
      `benefit-enrollments/${enrollment_id}/dependents/${dependent_id}`,
    );
  },

  getQualifyingEvents(): Promise<
    AxiosResponse<Array<QualifyingEventsResponse>>
  > {
    return Http.get<Array<QualifyingEventsResponse>>(`/qualifying-events`);
  },

  saveQualifyingEvent(
    payload: QualifyingEventRequest,
  ): Promise<AxiosResponse<StatusResponse>> {
    return Http.post<StatusResponse>(`/qualifying-events`, payload, {
      headers: {
        'Content-type': 'multipart/form-data',
      },
    });
  },
};
