import { defineAsyncComponent } from 'vue';

export const SpurLib = {
  install(Vue) {
    Vue.component(
      'sp-calendar-picker',
      defineAsyncComponent(() => import('./forms/inputs/CalendarPicker.vue')),
    );
    Vue.component(
      'sp-checkbox-input',
      defineAsyncComponent(() => import('./forms/inputs/CheckboxInput.vue')),
    );
    Vue.component(
      'sp-chevron-left',
      defineAsyncComponent(() => import('./icons/ChevronLeft.vue')),
    );
    Vue.component(
      'sp-chevron-right',
      defineAsyncComponent(() => import('./icons/ChevronRight.vue')),
    );
    Vue.component(
      'sp-error',
      defineAsyncComponent(() => import('./icons/Error.vue')),
    );
    Vue.component(
      'sp-form-error',
      defineAsyncComponent(() => import('./forms/FormError.vue')),
    );
    Vue.component(
      'sp-form-label',
      defineAsyncComponent(() => import('./forms/FormLabel.vue')),
    );
    Vue.component(
      'sp-full-logo',
      defineAsyncComponent(() => import('./FullLogo.vue')),
    );
    Vue.component(
      'sp-ghost-button',
      defineAsyncComponent(() => import('./GhostButton.vue')),
    );
    Vue.component(
      'sp-logo',
      defineAsyncComponent(() => import('./Logo.vue')),
    );
    Vue.component(
      'sp-modal',
      defineAsyncComponent(() => import('./popups/Modal.vue')),
    );
    Vue.component(
      'sp-nav-bar',
      defineAsyncComponent(() => import('./NavBar.vue')),
    );
    Vue.component(
      'sp-overflow-table',
      defineAsyncComponent(() => import('./OverflowTable.vue')),
    );
    Vue.component(
      'sp-page-take-over',
      defineAsyncComponent(() => import('./popups/PageTakeOver.vue')),
    );
    Vue.component(
      'sp-page-title',
      defineAsyncComponent(() => import('./PageTitle.vue')),
    );
    Vue.component(
      'sp-question-mark',
      defineAsyncComponent(() => import('./icons/QuestionMark.vue')),
    );
    Vue.component(
      'sp-select-input',
      defineAsyncComponent(() => import('./forms/inputs/SelectInput.vue')),
    );
    Vue.component(
      'sp-sub-heading',
      defineAsyncComponent(() => import('./SubHeading.vue')),
    );
    Vue.component(
      'sp-switch-toggle',
      defineAsyncComponent(() => import('./forms/inputs/SwitchToggle.vue')),
    );
    Vue.component(
      'sp-text-input',
      defineAsyncComponent(() => import('./forms/inputs/TextInput.vue')),
    );
    Vue.component(
      'sp-times',
      defineAsyncComponent(() => import('./icons/Times.vue')),
    );
    Vue.component(
      'sp-tool-tip',
      defineAsyncComponent(() => import('./ToolTip.vue')),
    );
    Vue.component(
      'sp-user',
      defineAsyncComponent(() => import('./icons/User.vue')),
    );
    Vue.component(
      'sp-user-dropdown',
      defineAsyncComponent(() => import('./UserDropdown.vue')),
    );
  },
};
