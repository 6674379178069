/* eslint-disable */
export default {
  AUTH: {
    ACTIONS: {
      LOGIN: "Iniciar sesión",
      LOGIN_WITH_FACEBOOK: "Iniciar sesión con Facebook",
      LOGOUT: "Cerrar sesión",
      RESET_PASSWORD: "Restablecer la contraseña",
      SIGN_WITH_GOOGLE: "Inicia sesión con Google"
    },
    LABELS: {
      EMAIL: "Correo electrónico",
      PASSWORD: "Contraseña",
      USERNAME: "Nombre de usuario"
    },
    QUESTIONS: {
      FORGOT_PASSWORD: "¿Olvidó su contraseña?",
      HAVE_AN_ACCOUNT: "¿Tener una cuenta?"
    },
    TOASTS: {
      UPDATE_PROFILE: "Perfil actualizado."
    }
  },
  BASE: {
    ACTIONS: {
      DECLINE: "Disminución",
      LEARN_MORE: "Aprender más",
      TOGGLE_MENU: "Menú de alternancia",
      UPLOAD_IMAGE: "Cargar imagen",
      VIEW: "Ver",
      VIEW_MORE: "Ver más",
      VISIT: "Visitar"
    },
    LABELS: {
      EVENT: "Evento",
      OCCURED: "Paso",
      STATUS: "Estado",
      SUBMITTED: "Presentado"
    },
    OPTIONS: {
      NO: "No",
      YES: "Sí"
    },
    STATUSES: {
      APPROVED: "Aprobado",
      PENDING: "Pendiente",
      REJECTED: "Rechazado"
    },
    TEXT: {
      HERE: "aquí",
      NOT_AVAILABLE: "no aplica"
    },
    TOASTS: {
      INTERNAL_SERVER_ERROR: "Algo salió mal.",
      UNAUTHORIZED: "Has sido desconectado."
    }
  },
  BENEFITS: {
    ACTIONS: {
      ADD_BENEFICIARY: "Añadir Beneficiario",
      ADD_DEPENDENT: "Añadir dependiente",
      ADD_QUALIFYING_EVENT: "Añadir evento clasificatorio",
      CANCEL_COVERAGE: "Cancelar cobertura",
      CREATE_BENEFICIARY: "Crear Beneficiario",
      CREATE_DEPENDENT: "Crear dependiente",
      CREATE_QUALIFYING_EVENT: "Crear evento clasificatorio",
      ENROLL: "Inscribirse",
      SELECT_REASON: "Seleccionar motivo...",
      SELECT_RELATIONSHIP: "Seleccionar relación...",
      SWITCH_PLAN: "Cambiar plan"
    },
    CHECKBOXES: {
      IMMEDIATE_COVERAGE: "Opcional: Me gustaría solicitar cobertura efectiva inmediata para mis beneficios de salud pagando por adelantado la prima del primer mes.",
      TERMS: "Acepto los términos para la inscripción y la continuación de la cobertura de beneficios durante todo el año del plan."
    },
    HELPERS: {
      ADD_DEPENDENTS_1: "¿Tienes algún dependiente? Toque ",
      ADD_DEPENDENTS_2: " para agregar uno.",
      ADD_QUALIFYING_EVENT: "Un evento calificado es un evento que cambia la vida, como casarse, tener un bebé o perder su trabajo, que lo hace elegible para inscribirse en beneficios fuera de un período de inscripción abierta.",
      BENEFICIARY_AGE: "Tenga en cuenta: los niños menores de 18 años pueden ser designados como beneficiarios primarios o contingentes. Sin embargo, los beneficios no pueden ser dados mientras sean menores de edad, los ingresos pueden ser enviado para al tutor legal del patrimonio del menor.",
      CANCEL_COVERAGE: "Si cancela la inscripción de su plan, no podrá inscribirse en la cobertura hasta que experimente un evento calificado o una inscripción abierta.",
      CHOOSE_PLAN: "Elija un plan de las siguientes opciones.",
      DELETE_DEPENDENT: "Si elimina este dependiente, se eliminarán de cualquier plan en el que esté inscrito actualmente.",
      ENROLL_DEPENDENT: "Para inscribir a este dependiente, deberá enviar un evento calificado. ¿Te gustaria continuar?",
      REMOVE_DEPENDENT: "Si elimina a este dependiente de su plan, no podrá inscribirlo en la cobertura de beneficios hasta que experimente un evento calificado o una inscripción abierta.",
      SELECT_GENDER: "Para continuar con los beneficios y la cancelación, debe seleccionar un género."
    },
    LABELS: {
      ADULT_CARE: "Cuidado de adultos",
      APPROVAL_PENDING: "Aprobación pendiente",
      BENEFITS_SUMMARY: "Resumen de beneficios",
      CHILD_CARE: "Cuidado de los niños",
      COST: "Costo",
      COVERAGE: "Cobertura",
      DATE_OF_EVENT: "Fecha del evento",
      DAY_CAMP: "Cuidado Diurno",
      DENTAL: "Dental",
      DEPENDENTS: "Dependientes",
      EDUCATION: "Educación",
      EFFECTIVE: "Effective",
      ELECTRONICS: "Electrónica",
      ENROLLED: "Inscrito",
      ENTERTAINMENT: "Entretenimiento",
      EVENT_TYPE: "Tipo de evento",
      EVERYDAY_EXPENSES: "Gastos diarios",
      EYE_CARE: "Cuidado de ojos",
      FIRST_DEDUCTION: "Moda + belleza",
      FASHION_BEAUTY: "Primera deducción",
      HEALTH_WELLNESS: "Salud + Bienestar",
      INSURANCE: "Seguro",
      MASS_TRANSIT: "Tránsito masivo",
      MEDICAL_EXPENSES: "Gastos médicos",
      PARKING: "Estacionamiento",
      PLAN: "El plano",
      PROOF_OF_EVENT: "Prueba de evento",
      PROVIDER: "Proveedor",
      RELATIONSHIP: "Relación",
      SELF: "Yo",
      STEP: "Paso",
      TOTAL: "Total",
      TRAVEL: "Viaje",
      WAIVED: "Renunciado",
      WEEKLY: "Semanal"
    },
    LINKS: {
      ABENITY: "http://spur.employeeperks.com",
    },
    NAV: {
      BENEFICIARIES: "Beneficiarios",
      BENEFITS: "Beneficios",
      DASHBOARD: "Tablero",
      DENTAL: "dental",
      DEPENDENTS: "Dependientes",
      MEDICAL: "beneficios medicos",
      PERKS: "Programa de beneficios",
      QUALIFYING_EVENTS: "Eventos calificativos",
      SHORT_TERM_DISABILITY: "incapacidad a corto plazo",
      TERM_LIFE: "plazo de vida",
      VISION: "visión"
    },
    QUESTIONS: {
      HOW_DO_I_ENROLL: "¿Cómo me inscribo?",
      WHAT_DO_I_SAVE_MONEY_ON: "¿En qué ahorro dinero?",
      WHAT_IS_SPURS_PERKS_PROGRAM: "¿Qué es el programa de beneficios de Spur?"
    },
    STEPS: {
      PERKS: {
        STEP_2: 'Seleccione "Olvidé mi nombre de usuario o contraseña" en el cuadro amarillo en la parte superior de la pantalla.',
        STEP_3: "Ingrese su dirección de correo electrónico que utiliza para acceder a Spur.",
        STEP_4: 'Toque en "Enviar instrucciones".',
        STEP_5: "Consulte su correo electrónico para obtener instrucciones sobre cómo restablecer su contraseña. Sigue esas instrucciones.",
        STEP_6: "¡Comience a ahorrar en el gasto que importa!"
      },
    },
    TEASERS: {
      DENTAL: "Inscríbase en un plan dental nacional que incluye 80% de cobertura para servicios preventivos, 60% de cobertura para servicios básicos y 50% de cobertura para servicios principales.",
      DISABILITY_TERM_LIFE: "Asegure el bienestar financiero de usted y su familia inscribiéndose en un plan de vida a corto plazo por discapacidad / plazo.",
      MEDICAL: "Elija el plan médico que funcione para usted, su familia y su billetera con costos que comienzan desde $ 17.56 por semana para una cobertura que haga la diferencia.",
      PERKS: "Diviértete por menos con acceso a más de 300,000 descuentos en todo, desde entradas de cine hasta paquetes de vacaciones.",
      VISION: "Inscríbase en un plan de visión nacional que incluye exámenes de visión anuales gratuitos y una asignación anual de $ 150 para marcos o una asignación anual de $ 130 para contactos."
    },
    TEXT: {
      ABENTITY_QUESTIONS: "Envíe cualquier pregunta al equipo de Abenity al ",
      ADULT_CARE: "Incluye gastos de transporte.",
      CHILD_CARE: "Guardería, niñeras, algunos preescolares",
      CLOSED: "La inscripción está cerrada hasta",
      DAY_CAMP: "Campamento de verano, antes de la escuela, después de la escuela.",
      DENTAL: "Limpieza, radiografías, procedimientos.",
      EDUCATION: "Servicios de cuidado infantil, programas de idiomas extranjeros, programas de educación superior, programas de escuelas comerciales",
      ELECTRONICS: "Televisión por cable, teléfonos celulares, computadoras, software",
      ENTERTAINMENT: "Pases para parques de diversiones, cenas, entradas para el cine, pases para eventos deportivos",
      EVERYDAY_EXPENSES: "Servicios de reparación de automóviles, muebles, comestibles, servicios de mejoras para el hogar.",
      EYE_CARE: "Gafas, contactos",
      FASHION_BEAUTY: "Ropa, joyas, zapatos, tratamientos de spa.",
      HEALTH_WELLNESS: "Clubes deportivos, anteojos, servicios de entrenamiento personal, beneficios de farmacia.",
      INSURANCE: "Seguro de auto, servicios de robo de identidad, programas de seguro de vida, seguro de mascotas",
      MASS_TRANSIT: "Metro, tren, ferry, autobús",
      MEDICAL_EXPENSES: "Médico copagos, coseguro",
      OPEN: "La inscripción termina en",
      PARKING: "Estacionamiento en la calle, estacionamientos, garajes",
      PERKS: "Ahora tiene acceso a más de 302,000 descuentos exclusivos en todo, desde pases para parques de diversiones y entradas para películas hasta alquileres de automóviles y planes de teléfonos celulares a través de nuestra asociación con Abenity. ¡Acceda a su cuenta para comenzar a gastar menos dinero en más diversión para usted, sus amigos y su familia!",
      PLAN: "Plano de {category}",
      QUALIFYING_EVENT: "Has enviado un evento calificativo. Debe ser aprobado antes de que se pueda completar su inscripción. Estamos revisando su evento y le informaremos tan pronto como se apruebe.",
      TRAVEL: "Alquiler de autos, cruceros, hoteles, paquetes de vacaciones"
    },
    TOASTS: {
      ADD_BENEFICIARY: "Beneficiario añadido.",
      ADD_COVERAGE: "Cobertura agregada.",
      ADD_DEPENDENT: "Dependiente agregado.",
      ADD_QUALIFYING_EVENT: "Evento clasificatorio agregado.",
      CANCEL_COVERAGE: "Cobertura cancelad.",
      DELETE_BENEFICIARY: "Beneficiario quitado.",
      DELETE_DEPENDENT: "Dependiente borrado.",
      REMOVE_DEPENDENT: "Dependiente eliminado."
    },
    TOOLTIPS: {
      CLOSED: "Para inscribirse, debe esperar la próxima inscripción abierta o enviar un evento calificado.",
      OPEN: "Después de que finalice la inscripción, deberá esperar a la próxima inscripción abierta o enviar un evento calificado para volver a inscribirse."
    }
  },
  FOOTER: {
    LINKS: {
      PRIVACY: "https://spurjobs.com/privacy-policy/es/",
      TERMS: "https://spurjobs.com/terms-of-service/"
    },
    NAV: {
      PRIVACY: "Política de Privacidad",
      TERMS: "Términos de Servicio"
    },
    TEXT: {
      COPYRIGHT: "Todos los derechos reservados."
    }
  },
  FORMS: {
    ACTIONS: {
      CANCEL: "Cancelar",
      DELETE: "Borrar",
      EDIT: "Editar",
      NEXT: "Próxima",
      RESET: "Reiniciar",
      REMOVE: "Eliminar",
      SELECT: "Seleccionar",
      SUBMIT: "Enviar",
      TOGGLE: "Palanca"
    },
    LABELS: {
      OPTIONAL: "Opcional"
    },
    QUESTIONS: {
      ARE_YOU_SURE: "¿Estás seguro?"
    }
  },
  TIME: {
    LABELS: {
      DAY: "día",
      DAYS: "dias"
    }
  },
  USER: {
    ACTIONS: {
      EDIT_PROFILE: "Editar perfil",
      SELECT_GENDER: "Seleccione género...",
      SELECT_STATE: "Seleccione estado...",
      UPDATE_PROFILE: "Actualización del perfil"
    },
    HEADINGS: {
      ACCOUNT_INFORMATION: "Información de la cuenta",
      PROFILE: "Perfil"
    },
    LABELS: {
      ADDRESS: "Dirección",
      AGE: "Años",
      BIRTH_DATE: "Fecha de nacimiento",
      CITY: "Ciudad",
      FIRST_NAME: "Nombre de pila",
      GENDER: "Género",
      LAST_NAME: "Apellido",
      MIDDLE_INITIAL: "Inicial del segundo nombre",
      MIDDLE_NAME: "Segundo nombre",
      NAME: "Nombre",
      POSTAL_CODE: "Código postal",
      PHONE: "Teléfono",
      REGION: "Región",
      SSN: "Número de seguridad social"
    }
  }
};
