import Rollbar from 'rollbar';
import { ActionTree } from 'vuex';

export const actions: ActionTree<any, any> = {
  report({ rootState }, { message, level }) {
    if (process.env.VUE_APP_RB_TOKEN === undefined) return;

    const rollbar = new Rollbar({
      accessToken: process.env.VUE_APP_RB_TOKEN,
      payload: {
        environment: process.env.VUE_APP_MODE,
        person: {
          id: rootState.auth.user ? rootState.auth.user.id : 'not set',
          email: rootState.auth.user ? rootState.auth.user.email : '',
        },
      },
    });

    rollbar[level](message);
  },
};

export default { actions, namespaced: true };
