import { AxiosResponse } from 'axios';

import {
  AuthRequestPayload,
  AuthResponse,
  ResetPasswordRequestPayload,
} from '@/store/auth/auth.types';
import { Http } from '@/lib/common/http';
import { StatusResponse } from '@/lib/services/base.types';

export const AuthService = {
  login(
    authRequestPayload: AuthRequestPayload,
  ): Promise<AxiosResponse<AuthResponse>> {
    return Http.post<AuthResponse>('/auth', authRequestPayload);
  },

  resetPassword(
    resetPasswordRequestPayload: ResetPasswordRequestPayload,
  ): Promise<AxiosResponse<StatusResponse>> {
    return Http.post<StatusResponse>('/password', resetPasswordRequestPayload);
  },
};
