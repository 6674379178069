import { BenefitsRelationships } from '@/lib/services/benefits/benefits.types';
import { Genders } from '@/lib/services/base.types';

export function getAge(birth_date: string) {
  const today = new Date();
  const birthDate = new Date(birth_date);

  let age = today.getFullYear() - birthDate.getFullYear();
  const monthDiff = today.getMonth() - birthDate.getMonth();

  if (
    monthDiff < 0 ||
    (monthDiff === 0 && today.getDate() < birthDate.getDate())
  ) {
    age = age - 1;
  }
  return age;
}

export function getGender(key: string) {
  return Genders[key];
}

export function getRelationship(key: string) {
  return BenefitsRelationships[key];
}

export function parseDate(date: string) {
  const parsed = Date.parse(date);
  if (!isNaN(parsed)) {
    return parsed;
  }

  return Date.parse(date.replace(/-/g, '/').replace(/[a-z]+/gi, ' '));
}
