import { createI18n } from 'vue-i18n';

import en from '@/i18n/en';
import es from '@/i18n/es';

export default createI18n({
  locale: ((navigator.languages && navigator.languages[0]) || '').substring(
    0,
    2,
  ),
  fallbackLocale: 'en',
  silentTranslationWarn: true,
  messages: { en: en, es: es },
  numberFormats: {
    'en-US': {
      currency: {
        style: 'currency',
        currency: 'USD',
      },
    },
  },
});
