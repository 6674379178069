export interface BenefitEnrollmentsRequest {
  plan_id: number;
  enroll: Array<number>;
  waive: Array<number>;
}

export interface BenefitEnrollmentsResponse {
  id: number;
  benefit_plan_coverage_id: number;
  rate_cents: number;
  rate_interval: string;
  created: string;
  approved_at: string;
  starts_on: string;
  plan: Plan;
  coverage: Coverage;
  event_type?: string;
  effective_coverage_date: string;
  first_deduction_date: string;
  immediate_coverage_requested: boolean;
  dependents: Array<Dependent>;
}

export interface BenefitsBeneficiaryRequest {
  first_name: string;
  middle_name: string;
  last_name: string;
  gender: string;
  birth_date: string;
  ssn: string;
  relationship: string;
  address: string;
  city: string;
  region: string;
  postal_code: string;
}

export interface BenefitsDependentRequest {
  first_name: string;
  middle_name: string;
  last_name: string;
  gender: string;
  birth_date: string;
  ssn: string;
  relationship: string;
  address: string;
  city: string;
  region: string;
  postal_code: string;
}

export interface BenefitsBeneficiaryResponse {
  id: number;
  first_name: string;
  middle_name: string;
  last_name: string;
  gender: string;
  birth_date: string;
  ssn: string;
  relationship: string;
  address: string;
}

export interface BenefitsDependentResponse {
  id: number;
  first_name: string;
  middle_name: string;
  last_name: string;
  gender: string;
  birth_date: string;
  ssn: string;
  relationship: string;
  address: string;
}

export interface BenefitsPlansCoveragesResponse {
  id: number;
  type: string;
  category: string;
  type_label: string;
  description: string;
  base_rate_cents: number;
  base_rate_next_year_cents: number;
  rate_interval: string;
  includes_spouse: boolean;
  includes_children: boolean;
}

export interface BenefitsProviderPlansResponse {
  id: number;
  name: string;
  type: string;
  type_label: string;
  description: string;
  tier: number;
}

export interface BenefitsProvidersResponse {
  id: number;
  name: string;
  type: string;
  type_label: string;
}

export interface Coverage {
  id: number;
  type: string;
  type_label: string;
  description: string;
  base_rate_cents: number;
  base_rate_cents_2022: number;
  rate_interval: string;
  includes_spouse: boolean;
  includes_children: boolean;
}

export interface Dependent {
  id: number;
  first_name: string;
  middle_name: string;
  last_name: string;
  gender: string;
  birth_date: string;
  ssn: string;
  address: string;
  relationship: string;
  action?: string;
}

export interface Plan {
  id: number;
  name: string;
  category: string;
  category_label: string;
  type: string;
  type_label: string;
  description: string;
  tier: number;
  provider: Provider;
  meta: any;
}

export interface Provider {
  id: number;
  name: string;
  type: string;
  type_label: string;
}

export interface QualifyingEvent {
  id: number;
  images: any;
  occurred_on: string;
  type: string;
  type_label: string;
  created: string;
  approved_at: string;
  rejected_at: string;
}

export interface QualifyingEventRequest {
  type: string;
  date: string;
  file: any;
}

export interface QualifyingEventsResponse {
  qualifyingEvents: Array<QualifyingEvent>;
}

export const BenefitsRelationships = {
  spouse: 'Spouse',
  ex_spouse: 'Ex-Spouse',
  domestic_partner: 'Domestic Partner',
  child: 'Child',
  stepchild: 'Stepchild',
  child_of_domestic_partner: 'Child of Domestic Partner',
  adopted_child: 'Adopted Child',
  foster_child: 'Foster Child',
  child_by_legal_guardianship: 'Child by Legal Guardianship',
};

export const BenefitsQualifyingEventTypes = {
  marriage: 'Marriage',
  separation: 'Divorce or separation',
  new_child: 'Birth or adoption of child',
  loss_of_employment: 'Loss of coverage due to involuntary loss of employment',
  reduction_in_hours: 'Loss of coverage due to reduction in hours worked',
  increase_of_hours: 'Eligibility for benefits due to increase in hours worked',
  chip_eligibility:
    'Eligibility for Medicare, Medicaid, or Children’s Health Insurance Program (CHIP)',
  permanent_residency: 'Relocation to the USA as a permanent resident',
  court_order: 'Court order',
  other: 'Other',
};
