import { createStore } from 'vuex';

import authStoreModule from '@/store/auth/auth.store';
import benefitsStoreModule from '@/store/benefits';
import errorsStoreModule from '@/store/errors/errors.store';
import notificationsStoreModule from '@/store/notifications/notifications.store';
import rollbarStoreModule from '@/store/rollbar/rollbar.store';
import gigsStoreModule from '@/store/gigs';

export default createStore({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    auth: authStoreModule,
    benefits: benefitsStoreModule,
    errors: errorsStoreModule,
    gigs: gigsStoreModule,
    notifications: notificationsStoreModule,
    rollbar: rollbarStoreModule,
  },
});
