export class Errors {
  errors: any = {};

  constructor(errors = {}) {
    this.record(errors);
  }

  all() {
    return this.errors;
  }

  has(field: string) {
    let hasError = Object.prototype.hasOwnProperty.call(this.errors, field);

    if (!hasError) {
      const errors = Object.keys(this.errors).filter(
        (e) => e.startsWith(`${field}.`) || e.startsWith(`${field}[`),
      );
      hasError = errors.length > 0;
    }
    return hasError;
  }

  first(field: string) {
    const message = this.get(field)[0];

    return message;
  }

  get(field: string) {
    return this.errors[field] || [];
  }

  any() {
    return Object.keys(this.errors).length > 0;
  }

  record(errors = {}) {
    this.errors = errors;
  }

  clear(field: string) {
    if (!field) {
      this.errors = {};

      return;
    }

    const errors = Object.assign({}, this.errors);

    Object.keys(errors)
      .filter(
        (e) =>
          e === field || e.startsWith(`${field}.`) || e.startsWith(`${field}[`),
      )
      .forEach((e) => delete errors[e]);

    this.errors = errors;
  }
}

export interface ErrorsState {
  errors: {};
}
