import { AxiosResponse } from 'axios';

import { Http } from '@/lib/common/http';
import {
  AssignGigResponse,
  GigResponse,
  SearchGigsResponse,
} from './gigs.types';

export const GigsService = {
  assignGig(
    teamId: number,
    gigId: number,
  ): Promise<AxiosResponse<AssignGigResponse>> {
    return Http.post<AssignGigResponse>(`/jobs/${teamId}/schedule/${gigId}`);
  },

  getGig(gigId: number): Promise<AxiosResponse<GigResponse>> {
    return Http.get<GigResponse>(`/gigs/${gigId}`);
  },

  searchGigs(params: Object): Promise<AxiosResponse<SearchGigsResponse>> {
    return Http.get<SearchGigsResponse>(`/search/gigs`, {
      params: { ...params },
    });
  },
};
