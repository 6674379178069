import { MutationTree } from 'vuex';

import * as types from './../mutation.types';
import { BenefitsState } from './index';
import {
  BenefitEnrollmentsResponse,
  BenefitsBeneficiaryResponse,
  BenefitsDependentResponse,
  BenefitsPlansCoveragesResponse,
} from '@/lib/services/benefits/benefits.types';

export const mutations: MutationTree<BenefitsState> = {
  // Dependents
  [types.FETCH_DEPENDENTS_SUCCESS](
    state: BenefitsState,
    payload: Array<BenefitsDependentResponse>,
  ) {
    state.dependents = payload;
  },

  // Beneficiaries
  [types.FETCH_BENEFICIARIES_SUCCESS](
    state: BenefitsState,
    payload: Array<BenefitsBeneficiaryResponse>,
  ) {
    state.beneficiaries = payload;
  },

  // Enrollments
  [types.FETCH_BENEFIT_ENROLLMENTS_SUCCESS](
    state: BenefitsState,
    payload: Array<BenefitEnrollmentsResponse>,
  ) {
    state.benefitEnrollments = payload.reduce((acc, enrollment) => {
      acc[enrollment.plan.category] = enrollment;
      return acc;
    }, {});
  },

  // Plans
  [types.FETCH_PLANS_BY_TYPES_SUCCESS](
    state: BenefitsState,
    payload: { type: string; data: Array<BenefitsPlansCoveragesResponse> },
  ) {
    state.plans = {
      ...state.plans,
      [payload.type]: payload.data,
    };
  },

  [types.FETCH_ALL_PLANS_SUCCESS](
    state: BenefitsState,
    data: Array<BenefitsPlansCoveragesResponse>,
  ) {
    state.plans = data.reduce((acc, plan) => {
      if (!acc[plan.category]) {
        acc[plan.category] = [];
      }
      acc[plan.category].push(plan);
      return acc;
    }, {});
  },

  // Qualifying Events
  [types.SAVE_QUALIFYING_EVENT_SUCCESS](state: BenefitsState, payload) {
    state.qualifyingEvent = payload;
  },

  [types.FETCH_QUALIFYING_EVENTS_SUCCESS](state: BenefitsState, payload) {
    state.qualifyingEvents = payload;
  },

  // Reset
  [types.CLEAR_BENEFITS](state: BenefitsState) {
    state.dependents = [];
    state.benefitEnrollments = {};
    state.plans = {};
  },
};
