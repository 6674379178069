import { ActionTree, GetterTree, MutationTree } from 'vuex';

import * as types from './../mutation.types';
import { Errors, ErrorsState } from './errors.types';

export const state: ErrorsState = {
  errors: new Errors(),
};

export const getters: GetterTree<ErrorsState, any> = {
  errors(state: ErrorsState) {
    return state ? state.errors : {};
  },
};

export const actions: ActionTree<ErrorsState, any> = {
  clear({ commit }) {
    commit(types.CLEAR_ERRORS);
  },

  set({ commit }, errors) {
    commit(types.SET_ERRORS, errors);
  },
};

export const mutations: MutationTree<ErrorsState> = {
  [types.CLEAR_ERRORS](state: ErrorsState) {
    state.errors = new Errors();
  },

  [types.SET_ERRORS](state: ErrorsState, errors: any) {
    const errorObject = new Errors();
    errorObject.record(errors);
    state.errors = errorObject;
  },
};

export default { state, getters, actions, mutations, namespaced: true };
