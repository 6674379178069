import NProgress from 'nprogress';
import { createRouter, createWebHistory } from 'vue-router';

import { authGuard, guestGuard } from '@/lib/common/middlewares';
import { supportedBenefitCategories } from '@/views/benefits/benefits.constants';

function load(view: string) {
  return () => import(`./views/${view}.vue`);
}

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [
    {
      path: '',
      redirect: '/login',
    },
    ...guestGuard([
      {
        path: '/login',
        name: 'login',
        component: load('auth/Login'),
      },
      {
        path: '/password/reset',
        name: 'password.request',
        component: load('auth/ForgotPassword'),
      },
    ]),
    ...authGuard([
      {
        path: '',
        name: 'home',
        redirect: { name: 'benefits' },
      },
      {
        path: '/benefits',
        component: load('benefits/Index'),
        children: [
          {
            path: '',
            name: 'benefits',
            component: load('benefits/Dashboard'),
          },
          {
            path: 'enrollment',
            name: 'enrollment',
            component: load('benefits/enrollment/Enrollment'),
            children: [
              {
                path: ':category',
                name: 'enrollment-category',
                component: load('benefits/enrollment/EnrollmentCategory'),
                beforeEnter(to, from, next) {
                  if (
                    to.params &&
                    supportedBenefitCategories.includes(to.params.category)
                  ) {
                    return next();
                  }
                  return next({ name: 'benefits' });
                },
              },
            ],
          },
          {
            path: 'enrollments/:id',
            name: 'single-enrollment',
            component: load('benefits/enrollment/EnrollmentPlan'),
          },
          {
            path: 'dependents',
            name: 'dependents',
            component: load('benefits/Dependents'),
          },
          {
            path: 'beneficiaries',
            name: 'beneficiaries',
            component: load('benefits/Beneficiaries'),
          },
          {
            path: 'perks-program',
            name: 'perks-program',
            component: load('benefits/PerksProgram'),
          },
          {
            path: 'qualifying-events',
            name: 'qualifying-events',
            component: load('benefits/QualifyingEvents'),
          },
        ],
      },
      {
        path: '/profile',
        name: 'profile',
        component: load('Profile'),
      },
      {
        path: '/work',
        component: load('work/Index'),
        children: [
          {
            path: '',
            name: 'work',
            component: load('work/Dashboard'),
          },
        ],
      },
    ]),
    {
      path: '/:catchAll(.*)',
      redirect: '/404',
    },
    {
      path: '/404',
      name: '404',
      component: load('NotFound'),
    },
  ],
  scrollBehavior(to) {
    if (to.hash) {
      return {
        el: to.hash,
      };
    }
  },
});

router.beforeResolve((to, from, next) => {
  // If this isn't an initial page load.
  if (to.name) {
    // Start the route progress bar.
    NProgress.start();
  }
  next();
});

router.afterEach(() => {
  // Complete the animation of the route progress bar.
  NProgress.done();
});

export default router;
