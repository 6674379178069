import { Gig } from '@/lib/services/gigs/gigs.types';
import { actions } from './gigs.actions';
import { mutations } from './gigs.mutations';
import { getters } from './gigs.getters';

export interface GigState {
  gig: Gig | null;
  gigs: Array<Gig>;
  totalGigs: number;
}

export const state: GigState = {
  gig: null,
  gigs: [],
  totalGigs: -1,
};

export default { state, getters, actions, mutations, namespaced: true };
