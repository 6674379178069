<template>
  <div class="fixed right-0 top-0 flex-col z-1000 mt-5 mr-4">
    <transition-group name="toast" tag="div">
      <div
        class="toast-message rounded shadow px-4 py-5 my-2"
        v-for="n in notifications"
        :class="getClass(n.type)"
        :key="n.id"
      >
        <router-link v-if="n.link" :to="{ name: n.link, params: n.linkParams }">
          <p v-html="n.text"></p>
          <button
            class="toast-button"
            aria-label="Close"
            type="button"
            @click="close(n.id)"
          ></button>
        </router-link>
        <span v-else>
          <p v-html="n.text"></p>
          <button
            class="toast-button"
            aria-label="Close"
            type="button"
            @click="close(n.id)"
          ></button>
        </span>
      </div>
    </transition-group>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'SpToast',
  methods: {
    ...mapActions('notifications', ['remove']),

    close(id) {
      this.remove(id);
    },

    getClass(type) {
      switch (type) {
        case 'success':
          return 'bg-deep-aquamarine';
        case 'info':
          return 'bg-deep-blue-jeans';
        case 'danger':
          return 'bg-watermelon';

        default:
          return 'bg-deep-blue-jeans';
      }
    },
  },

  computed: {
    ...mapGetters('notifications', ['notifications']),
  },
};
</script>

<style scoped>
a {
  color: white;
  text-decoration: none;
}

.toast-message {
  color: white;
  position: relative;
  width: 350px;
  height: auto;
  top: 10px;
  right: 10px;
}

.toast-enter-active,
.toast-leave {
  opacity: 1;
}

.toast-enter,
.toast-leave-active {
  opacity: 0;
}

.toast-leave-active {
  position: absolute;
}

.toast-message.toast-enter,
.toast-message.toast-leave-active {
  transform: translateY(-20px);
}

.toast-button {
  position: absolute;
  top: 0;
  right: 5px;
  padding: 2px;
  background-color: transparent;
  border-width: 0;
  font-size: 1.5em;
  color: inherit;
  cursor: pointer;
}

.toast-button::before {
  content: '\d7';
}
</style>
