import flatten from 'lodash/flatten';
import unionBy from 'lodash/unionBy';
import { compareDesc } from 'date-fns';
import { GetterTree } from 'vuex';
import { TimeFormats } from '@/utils/timeFormats';
import { format } from 'date-fns';

import { BenefitsState } from './index';
import { parseDate } from '@/utils/transform';

export const getters: GetterTree<BenefitsState, any> = {
  // Depenedents
  dependentsForEnrollment: (state, getters) => (type) => {
    // If currently enrolled build dependent list based of which dependents are enrolled
    if (
      type &&
      getters.benefitEnrollments[type] &&
      getters.benefitEnrollments[type].dependents
    ) {
      // List of enrolled dependent ids
      const enrolledDependentIds = getters.benefitEnrollments[type].dependents
        .filter((dep) => dep.action === 'enroll')
        .map((dep) => dep.id);

      return state.dependents.map((d) => ({
        relationship: d.relationship,
        enrolled: enrolledDependentIds.includes(+d.id),
        name: `${d.first_name} ${d.last_name}`,
        id: d.id,
      }));
    }

    // If not enrolled return all dependent as not enrolled
    return state.dependents.map((d) => ({
      relationship: d.relationship,
      enrolled: false,
      name: `${d.first_name} ${d.last_name}`,
      id: d.id,
    }));
  },

  dependents: (state) => state.dependents,

  beneficiaries: (state) => state.beneficiaries,

  // Enrollments
  benefitEnrollments: (state) => state.benefitEnrollments,
  benefitEnrollmentsCollection: (state) =>
    // eslint-disable-next-line
    Object.entries(state.benefitEnrollments).map(([key, value]) => {
      return value;
    }),
  benefitEnrollmentById: (state) => (id) =>
    Object.values(state.benefitEnrollments).find((b) => b.id === id),
  enrollementByPlanType: (state) => (type) => state.benefitEnrollments[type],

  // Plans
  allPlans: (state) => flatten(Object.values(state.plans)),
  plansByType: (state) => (type) => state.plans[type] && state.plans[type],
  isEnrolledInPlanByType: (state) => (type, planId) =>
    state.benefitEnrollments[type] &&
    state.benefitEnrollments[type].plan.id === planId,

  // Qualifying Events
  mostRecentQualifyingEvent: (state) => {
    return (
      state.qualifyingEvents &&
      state.qualifyingEvents.length &&
      state.qualifyingEvents[0]
    );
  },
  qualifyingEvents: (state) => state.qualifyingEvents,

  // Navigation for benefits, pulls benefits user is enrolled in along with
  // plans that are open for enrollment
  benefitsNavigation(state, getters) {
    const benefitEnrollments =
      state.benefitEnrollments && Object.values(state.benefitEnrollments);

    return (
      getters.allPlans &&
      benefitEnrollments &&
      unionBy(
        benefitEnrollments,
        getters.allPlans,
        (d) => d.category || d.plan.category,
      ).map((d) => {
        return {
          ...d,
          effective_coverage_date: d.effective_coverage_date
            ? format(parseDate(d.effective_coverage_date), TimeFormats.DATE)
            : null,
          first_deduction_date: d.first_deduction_date
            ? format(parseDate(d.first_deduction_date), TimeFormats.DATE)
            : null,
          enrolled_in: Boolean(d.approved_at) && d.plan,
          pending: !d.approved_at,
          category: d.category || d.plan.category,
          category_label: d.category_label || d.plan.category_label,
          ends_on:
            d.provider && d.provider.enrollmentPeriods.length
              ? d.provider.enrollmentPeriods.sort((a, b) => {
                  return compareDesc(new Date(a.ends_on), new Date(b.ends_on));
                })[0].ends_on
              : null,
          required_enrollment:
            d.category === 'vision'
              ? benefitEnrollments.some(
                  (enrollment) => enrollment.plan.category === 'medical',
                )
                ? null
                : 'medical'
              : null,
        };
      })
    );
  },
};
