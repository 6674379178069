import { mapActions, mapGetters } from 'vuex';

export default {
  methods: {
    ...mapActions('errors', ['clear']),
    scrollToError(el) {
      return el !== null
        ? document
            .querySelector(el)
            .scrollIntoView({ behavior: 'smooth', block: 'start' })
        : false;
    },
  },
  computed: mapGetters('errors', ['errors']),
};
