<template>
  <div>
    <Toast />
    <router-view />
    <div id="page-takeovers"></div>
    <div id="modals"></div>
    <div id="bottom-bar"></div>
  </div>
</template>

<script>
import Toast from '@/lib/components/popups/Toast.vue';

export default {
  components: {
    Toast,
  },
  async mounted() {
    if (this.$store.getters['auth/user']) {
      await this.$store.dispatch('auth/getProfile');
    }
  },
};
</script>

<style lang="scss">
@import 'globals';
</style>
