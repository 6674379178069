import store from '@/store';

export function guestGuard(routes) {
  return guard(routes, (to, from, next) => {
    if (store.getters['auth/user']) {
      next({ name: 'home' });
    } else {
      next();
    }
  });
}

export function authGuard(routes) {
  return guard(routes, (to, from, next) => {
    if (!store.getters['auth/user']) {
      next({ name: 'login' });
    } else {
      next();
    }
  });
}

function guard(routes, guard) {
  routes.forEach((route) => {
    route.beforeEnter = guard;
  });

  return routes;
}
