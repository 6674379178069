/* eslint-disable */

// auth
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const SAVE_PROFILE = "SAVE_PROFILE";

// benefits
export const CLEAR_BENEFITS = "CLEAR_BENEFITS";
export const FETCH_ALL_PLANS_SUCCESS = "FETCH_ALL_PLANS_SUCCESS";
export const FETCH_BENEFICIARIES_SUCCESS = "FETCH_BENEFICIARIES_SUCCESS";
export const FETCH_BENEFIT_ENROLLMENTS_SUCCESS = "FETCH_BENEFIT_ENROLLMENTS_SUCCESS";
export const FETCH_DEPENDENTS_SUCCESS = "FETCH_DEPENDENTS_SUCCESS";
export const FETCH_PLANS_BY_TYPES_SUCCESS = "FETCH_PLANS_BY_TYPES_SUCCESS";
export const FETCH_QUALIFYING_EVENTS_SUCCESS = 'FETCH_QUALIFYING_EVENTS_SUCCESS';
export const SAVE_QUALIFYING_EVENT_SUCCESS = 'SAVE_QUALIFYING_EVENT_SUCCESS';

// work
export const FETCH_GIG_SUCCESS = "FETCH_GIG_SUCCESS";
export const FETCH_GIGS_SUCCESS = "FETCH_GIGS_SUCCESS";

// errors
export const CLEAR_ERRORS = "CLEAR_ERRORS";
export const SET_ERRORS = "SET_ERRORS";

// notifications
export const ADD_TOAST = "ADD_TOAST";
export const REMOVE_TOAST = "REMOVE_TOAST";
