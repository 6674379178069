import { ActionTree } from 'vuex';

import * as types from './../mutation.types';
import { GigsService } from '@/lib/services/gigs/gigs.service';
import { GigState } from '@/store/gigs/index';
import { AssignGigParams } from '@/lib/services/gigs/gigs.types';

export const actions: ActionTree<GigState, any> = {
  assignGig(context, params: AssignGigParams) {
    return GigsService.assignGig(params.team_id, params.gig_id);
  },

  fetchGig(context, gigId: number) {
    return GigsService.getGig(gigId).then(({ data }) =>
      context.commit(types.FETCH_GIG_SUCCESS, data),
    );
  },

  fetchGigs(context, params: Object) {
    return GigsService.searchGigs(params).then(({ data }) =>
      context.commit(types.FETCH_GIGS_SUCCESS, data),
    );
  },
};
