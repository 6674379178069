import { MutationTree } from 'vuex';

import * as types from './../mutation.types';
import { GigState } from './index';
import { Gig, SearchGigsResponse } from '@/lib/services/gigs/gigs.types';

export const mutations: MutationTree<GigState> = {
  [types.FETCH_GIG_SUCCESS](state: GigState, payload: Gig) {
    state.gig = payload;
  },

  [types.FETCH_GIGS_SUCCESS](state: GigState, payload: SearchGigsResponse) {
    state.gigs = payload.data;
    state.totalGigs = payload.meta.total;
  },
};
