import { ActionTree } from 'vuex';

import * as types from './../mutation.types';
import { BenefitsService } from '@/lib/services/benefits/benefits.service';
import { BenefitsState } from './index';

export const actions: ActionTree<BenefitsState, any> = {
  // Dependents
  fetchDependents(context) {
    return BenefitsService.getBenefitDependents()
      .then(({ data }) => context.commit(types.FETCH_DEPENDENTS_SUCCESS, data))
      .catch((e) => {
        // eslint-disable-next-line
        console.error(e);
      });
  },

  removeDependent(context, payload) {
    return BenefitsService.removeBenefitDependent(payload).then(() =>
      context.dispatch('fetchDependents'),
    );
  },

  // Beneficiaries
  fetchBeneficiaries(context) {
    return BenefitsService.getBenefitBeneficiaries()
      .then(({ data }) =>
        context.commit(types.FETCH_BENEFICIARIES_SUCCESS, data),
      )
      .catch((e) => {
        // eslint-disable-next-line
        console.error(e);
      });
  },

  removeBeneficiary(context, payload) {
    return BenefitsService.removeBenefitBeneficiary(payload).then(() =>
      context.dispatch('fetchBeneficiaries'),
    );
  },

  // Enrollments
  fetchBenefitEnrollments(context) {
    return BenefitsService.getBenefitEnrollments().then((res) =>
      context.commit(types.FETCH_BENEFIT_ENROLLMENTS_SUCCESS, res.data),
    );
  },

  saveBenefitEnrollment(context, payload) {
    if (
      context.getters.mostRecentQualifyingEvent &&
      context.getters.mostRecentQualifyingEvent.type &&
      context.getters.mostRecentQualifyingEvent.approved_at !== null
    ) {
      payload.qualifying_events = [
        context.getters.mostRecentQualifyingEvent.id,
      ];
    }

    return BenefitsService.saveBenefitEnrollment(payload).then(() =>
      context.dispatch('fetchBenefitEnrollments'),
    );
  },

  deleteBenefitEnrollment(context, payload) {
    return BenefitsService.deleteBenefitEnrollment(payload.enrollment_id).then(
      () => context.dispatch('fetchBenefitEnrollments'),
    );
  },

  removeDependentFromEnrollment(
    context,
    payload: { enrollment_id: number; dependent_id: number },
  ) {
    return BenefitsService.removeDependentFromEnrollment(
      payload.enrollment_id,
      payload.dependent_id,
    );
  },

  // Plans
  fetchPlansByType(context, type: string) {
    return BenefitsService.getPlans(type).then(({ data }) => {
      return context.commit(types.FETCH_PLANS_BY_TYPES_SUCCESS, { type, data });
    });
  },

  fetchAllPlans(context) {
    let qualifyingEventType;
    if (context.getters.mostRecentQualifyingEvent) {
      qualifyingEventType = context.getters.mostRecentQualifyingEvent.type;
    }

    return BenefitsService.getAllPlans(qualifyingEventType).then(({ data }) => {
      return context.commit(types.FETCH_ALL_PLANS_SUCCESS, data);
    });
  },

  // Qualifying Events
  fetchQualifyingEvents(context) {
    return BenefitsService.getQualifyingEvents().then(({ data }) => {
      return context.commit(types.FETCH_QUALIFYING_EVENTS_SUCCESS, data);
    });
  },

  saveQualifyingEvent(context, payload) {
    return BenefitsService.saveQualifyingEvent(payload).then(
      ({ data: result }) => {
        return context.commit(types.SAVE_QUALIFYING_EVENT_SUCCESS, result);
      },
    );
  },
};
