import { Http } from '@/lib/common/http';

const loadingDirectiveInterceptors = new Map();

const LoadingDirective = {
  beforeMount(el, binding) {
    if (!el.hasAttribute('data-loading-id')) {
      const id = (Date.now() + Math.random()).toString();
      el.setAttribute('data-loading-id', id);
      loadingDirectiveInterceptors.set(id, { el, binding });
    }
  },
  unmounted(el) {
    if (el.hasAttribute('data-loading-id')) {
      const id = el.dataset.loadingId;
      el.removeAttribute('data-loading-id');
      loadingDirectiveInterceptors.delete(id);
    }
  },
};

// Axios Interceptors iterate over map of elements with `v-loading` directive
// and add class `.loading` and can disable the element if the `v-loading.disable`
// modifier is added.

Http.interceptors.request.use(
  (config) => {
    loadingDirectiveInterceptors.forEach(({ el, binding }) => {
      el.classList.add('is-loading');
      if (binding.modifiers.disable) {
        el.setAttribute('disabled', '');
      }
    });

    return config;
  },
  (error) => {
    loadingDirectiveInterceptors.forEach(({ el, binding }) => {
      el.classList.add('is-loading');
      if (binding.modifiers.disable) {
        el.setAttribute('disabled', '');
      }
    });

    return Promise.reject(error);
  },
);

Http.interceptors.response.use(
  (response) => {
    loadingDirectiveInterceptors.forEach(({ el, binding }) => {
      if (el.classList.contains('is-loading')) {
        el.classList.remove('is-loading');
        if (binding.modifiers.disable) {
          el.removeAttribute('disabled');
        }
      }
    });

    return response;
  },
  (error) => {
    loadingDirectiveInterceptors.forEach(({ el, binding }) => {
      if (el.classList.contains('is-loading')) {
        el.classList.remove('is-loading');
        if (binding.modifiers.disable) {
          el.removeAttribute('disabled');
        }
      }
    });

    return Promise.reject(error);
  },
);

export default LoadingDirective;
