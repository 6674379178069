import {
  BenefitEnrollmentsResponse,
  BenefitsBeneficiaryResponse,
  BenefitsDependentResponse,
  BenefitsPlansCoveragesResponse,
  QualifyingEvent,
} from '@/lib/services/benefits/benefits.types';
import { actions } from './benefits.actions';
import { mutations } from './benefits.mutations';
import { getters } from './benefits.getters';

export interface BenefitsState {
  beneficiaries: Array<BenefitsBeneficiaryResponse>;
  dependents: Array<BenefitsDependentResponse>;
  benefitEnrollments: { [key: string]: BenefitEnrollmentsResponse };
  plans: { [key: string]: Array<BenefitsPlansCoveragesResponse> };
  qualifyingEvent: QualifyingEvent | null;
  qualifyingEvents: Array<any> | null;
}

export const state: BenefitsState = {
  beneficiaries: [],
  dependents: [],
  benefitEnrollments: {},
  plans: {},
  qualifyingEvent: null,
  qualifyingEvents: [],
};

export default { state, getters, actions, mutations, namespaced: true };
