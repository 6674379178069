import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { AxiosError, AxiosResponse } from 'axios';
import { getAnalytics, setUserId } from 'firebase/analytics';

import * as types from './../mutation.types';
import {
  AuthResponse,
  AuthState,
  AuthRequestPayload,
  AuthErrorResponse,
} from './auth.types';
import { AuthService } from '@/lib/services/auth/auth.service';
import { Http } from '@/lib/common/http';
import { ProfileResponse } from '@/lib/services/profile/profile.types';
import { ProfileService } from '@/lib/services/profile/profile.service';
import { SocialAuthService } from '@/lib/services/auth/social-auth.service';

const localUser = localStorage.getItem('user');

export const state: AuthState = {
  user: localUser ? JSON.parse(localUser) : null,
  profile: null,
};

if (state.user) {
  if (process.env.NODE_ENV === 'production') {
    setUserId(getAnalytics(), state.user.id.toString());
  }
}

export const getters: GetterTree<AuthState, any> = {
  token(state: AuthState) {
    return state.user ? state.user.token : null;
  },

  user(state: AuthState) {
    return state.user;
  },

  profile(state: AuthState) {
    return state.profile;
  },

  userHasGender(state: AuthState) {
    return state.profile && state.profile.gender;
  },
};

export const actions: ActionTree<AuthState, any> = {
  login({ commit, dispatch }, user: AuthRequestPayload) {
    return new Promise((resolve, reject) => {
      AuthService.login(user)
        .then((res: AxiosResponse<AuthResponse>) => {
          localStorage.setItem('user', JSON.stringify(res.data));
          Http.defaults.headers.common[
            'Authorization'
          ] = `Bearer ${res.data.token}`;
          commit(types.LOGIN_SUCCESS, res.data);
          resolve(res.data);
          dispatch('getProfile');
        })
        .catch((error: AxiosResponse<AuthErrorResponse>) => {
          reject(error);
          return error;
        });
    });
  },

  logout({ commit }) {
    return new Promise((resolve, reject) => {
      try {
        localStorage.removeItem('user');
        Http.defaults.headers.common['Authorization'] = '';
        commit(types.LOGOUT_SUCCESS);
        resolve(true);
      } catch (e) {
        reject(e);
      }
    });
  },

  socialSpurLogin({ commit }, { provider, email, account_id }) {
    return new Promise((resolve, reject) => {
      SocialAuthService.loginSpur(provider, email, account_id)
        .then((res) => {
          localStorage.setItem('user', JSON.stringify(res.data));
          Http.defaults.headers.common[
            'Authorization'
          ] = `Bearer ${res.data.token}`;
          commit(types.LOGIN_SUCCESS, res.data);
          resolve(res.data);
        })
        .catch((error: AxiosError) => reject(error.response));
    });
  },

  getProfile({ commit }) {
    return ProfileService.get().then((res) => {
      commit(types.SAVE_PROFILE, res.data);
    });
  },

  updateProfile({ commit }, profile) {
    return ProfileService.update(profile).then((res) => {
      commit(types.SAVE_PROFILE, res.data);
    });
  },
};

export const mutations: MutationTree<AuthState> = {
  [types.LOGIN_SUCCESS](state: AuthState, user: AuthResponse) {
    if (process.env.NODE_ENV === 'production') {
      setUserId(getAnalytics(), user.id.toString());
    }
    state.user = user;
  },

  [types.LOGOUT_SUCCESS](state: AuthState) {
    state.user = null;
    state.profile = null;
  },

  [types.SAVE_PROFILE](state: AuthState, payload: ProfileResponse) {
    state.profile = payload;
  },
};

export default { state, getters, actions, mutations, namespaced: true };
