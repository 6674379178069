import * as types from './../mutation.types';

// state
const state = {
  toastID: 0,
  dismissAfter: 3500,
  notifications: [],
};

// getters
const getters = {
  notifications: (state) => state.notifications,
};

// actions
const actions = {
  notify({ commit, state }, toast) {
    const notification = {
      ...{
        id: state.toastID + 1,
        dismissAfter: state.dismissAfter,
        is_sticky: false,
        link: null,
        linkParams: {},
      },
      ...toast,
    };

    commit(types.ADD_TOAST, notification);

    if (!notification.is_sticky) {
      setTimeout(
        () => commit(types.REMOVE_TOAST, notification.id),
        notification.dismissAfter,
      );
    }
  },

  remove({ commit }, id) {
    commit(types.REMOVE_TOAST, id);
  },
};

// mutations
const mutations = {
  [types.ADD_TOAST](state, toast) {
    state.toastID = toast.id;
    state.notifications.push(toast);
  },
  [types.REMOVE_TOAST](state, id) {
    state.notifications = state.notifications.filter((n) => n.id !== id);
  },
};

export default { state, getters, mutations, actions, namespaced: true };
