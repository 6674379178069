import NProgress from 'nprogress';
import { createApp } from 'vue';

import VueGoodTablePlugin from 'vue-good-table-next';
import 'vue-good-table-next/dist/vue-good-table-next.css';

import '@/lib/services/firebase/init';
import './registerServiceWorker';
import '@/directives/loading';

import App from './App.vue';
import InteractsWithErrors from './mixins/InteractsWithErrors';
import i18n from './i18n';
import Rollbar from 'rollbar';
import router from './router';
import store from './store';
import { SpurLib } from '@/lib/components';
import { createGtm } from '@gtm-support/vue-gtm';
import LoadingDirective from '@/directives/loading';

// todo: get mock working again?
// if (process.env.NODE_ENV === 'development') {
//   const { worker } = require('./_mocks/browser');
//   worker.start();
// }

const app = createApp(App);

NProgress.configure({ showSpinner: false });

app.use(VueGoodTablePlugin);

app.config.globalProperties.rollbar = () =>
  new Rollbar({
    accessToken: process.env.VUE_APP_RB_TOKEN,
    payload: {
      environment: process.env.VUE_APP_MODE,
    },
  });

app.use(SpurLib);

app.mixin(InteractsWithErrors);

app.use(i18n);
app.use(store);
app.use(router);
app.directive('loading', LoadingDirective);

if (process.env.VUE_APP_MODE === 'production') {
  app.use(
    createGtm({
      id: 'GTM-NZZ55D4',
      enabled: true,
      debug: true,
      loadScript: true,
      vueRouter: router,
    }),
  );
}

app.mount('#app');
